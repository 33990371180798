
// Variables de entorno y constantes de la aplicación
export const environment = {
  production: false,
  apiUrl: 'https://api.lswr-guia.com/api',
  assetsUrl: 'https://api.lswr-guia.com',
  emailRegex: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
  phoneRegex: new RegExp(/^\+?\d*$/), // new RegExp(/^\+?\d*$/)
  passwordRegex: new RegExp(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
  dateRegex: new RegExp(/^(0?[1-9]|[12][\d]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/),
};