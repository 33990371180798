import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/i18n/translation.service';
import { StorageKey } from './core/interfaces/storage';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'angular';

  constructor(
    private router: Router,
    private readonly storage: StorageService,
    private readonly translationService: TranslationService,
  ) {
    this.setupLanguage();
  }

  ngOnInit() {
    this.checkStorageData();
  }

  setupLanguage(): void {
    this.translationService.init();
  }

  async checkStorageData(): Promise<void> {
    const userData = await this.storage.getItem(StorageKey.userData);
    if (!userData) { this.router.navigate(['public/login']) }
  }
}
